<template>
    <div ref="overlay" class="loading-overlay">
        <div class="spinner"></div>
    </div>
</template>

<script>
export default {
    name: 'LoadingOverlay'
}
</script>

<style>
@import '../../assets/css/loading-overlay.css';
</style>